<template>
  <router-view v-if="$route.name === 'reports-details'" />
  <section v-else class="section">
    <h2 class="title">
      {{ $t('route.reports') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.reports') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t('newReport') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="title" :label="$t('title')" width="15%">
          <template slot-scope="{ value, item: report }">
            <router-link v-if="report.id" :to="{ name: 'reports-details', params: { id: report.id } }">
              {{ value }}
            </router-link>
          </template>
        </data-column>
        <data-column field="description" :label="$t('description')" />
        <data-column field="repeat" :label="$t('repeat')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value">{{ $t('report.' + value) }}</span>
            <span v-else>N/A</span>
          </template>
        </data-column>
        <data-column field="enabled" :label="$t('status')" width="10%">
          <template slot-scope="{ value }">
            <span v-if="value" class="tag is-success is-light">{{ $t('enabled') }}</span>
            <span v-else class="tag is-warning is-light">{{ $t('disabled') }}</span>
          </template>
        </data-column>
        <data-column field="last" :label="$t('lastReport')" width="15%">
          <template slot-scope="props">
            {{ props.value ? format(new Date(props.value), 'dd/MM/yyyy HH:mm:ss') : 'None' }}
          </template>
        </data-column>
        <data-column
          label="Action" class="has-text-centered" :sortable="false"
          width="25%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <router-link :to="{ name: 'reports-details', params: { id: props.item.id, edit: true } }" class="button is-text">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </router-link>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
              <button class="button is-text" @click.prevent="plan(props.item)">
                <octicon :icon="clock" /> <span>{{ $t('schedule') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            {{ item.name || $t('newReport') }}
          </div>

          <template v-if="schedule">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">{{ $t('repeat') }}</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="item.repeat" required>
                        <option disabled value="">
                          {{ $t('selectDefault') }}
                        </option>
                        <option v-for="repeat in repeats" :key="repeat" :value="repeat">
                          {{ $t(`report.${repeat}`) }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.repeat === 'monthly'" class="column">
                <div class="field">
                  <label class="label">{{ $t('dayOfTheMonth') }}</label>
                  <div class="control">
                    <day-picker :value.sync="item.day" />
                  </div>
                </div>
              </div>
              <div v-if="item.repeat === 'weekly'" class="column">
                <div class="field">
                  <label class="label">{{ $t('dayOfTheWeek') }}</label>
                  <weekday-picker :value.sync="item.weekday" />
                </div>
              </div>
              <div v-if="item.repeat" class="column">
                <div class="field">
                  <label class="label">{{ $t('time') }}</label>
                  <time-picker :time.sync="item.time" />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="field">
              <label class="label">{{ $t('title') }}</label>
              <div class="control">
                <input
                  v-model="item.title" class="input" type="text"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('description') }}</label>
              <div class="control">
                <input v-model="item.description" class="input" type="text">
              </div>
            </div>

            <div class="field" @keydown.enter.prevent>
              <label class="label">{{ $t('recipients') }}</label>
              <v-select
                v-model="item.recipients" :options="recipients" :multiple="true"
                :taggable="true"
              />
            </div>
          </template>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal, TimePicker, WeekdayPicker, DayPicker } from '@cyradar/ui'
import { plus, x, pencil, clock } from 'octicons-vue'
import { format } from 'date-fns'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export const repeats = ['daily', 'weekly', 'monthly']

export default {
  components: { DataTable, DataColumn, Modal, DayPicker, TimePicker, WeekdayPicker, vSelect },
  data () {
    return {
      modal: false,
      item: null,
      schedule: false,
      recipients: []
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    clock () {
      return clock
    },
    repeats () {
      return repeats
    }
  },
  methods: {
    format,
    closeModal () {
      this.modal = false
    },
    submit () {
      this.store()
    },
    create () {
      this.item = {}
      this.modal = true
    },
    plan (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.schedule = true
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/reports', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      return this.$http.post('/api/v1/reports', this.item).then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$router.push({ name: 'reports-details', params: { id: body.data.data.id } })
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/reports/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
