<template>
  <div v-if="item" style="overflow: hidden;">
    <div class="reports-details">
      <box style="margin-bottom: 0;">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="is-size-5 has-text-weight-bold">
              <input
                v-if="customizing" v-model="item.title" type="text"
                class="input--simple"
              >
              <span v-else>{{ item.title }}</span>
            </h1>
            <h3 class="is-size-7">
              <input
                v-if="customizing" v-model="item.description" type="text"
                class="input--simple is-small"
              >
              <span v-else>{{ item.description }}</span>
            </h3>
          </div>
          <div class="column is-narrow">
            <button
              :class="{'button is-small is-light': true, 'is-success': item.enabled, 'is-warning': !item.enabled}"
              @click.prevent="toggleItemStatus"
              v-text="item.enabled ? $t('enabled') : $t('disabled')"
            />
          </div>

          <div class="column is-narrow">
            <range-picker
              ref="rangepicker" :disabled="!customizing"
              small :range.sync="itemDates" :lookup.sync="item.range"
            />
          </div>

          <div class="column is-narrow">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth is-small">
                      <select v-model="item.repeat" required :disabled="!customizing">
                        <option disabled value="">
                          {{ $t('selectDefault') }}
                        </option>
                        <option v-for="repeat in repeats" :key="repeat" :value="repeat">
                          {{ $t(`report.${repeat}`) }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.repeat === 'monthly'" class="column">
                <div class="field">
                  <div class="control">
                    <day-picker :value.sync="item.day" small :disabled="!customizing" />
                  </div>
                </div>
              </div>
              <div v-if="item.repeat === 'weekly'" class="column">
                <div class="field">
                  <weekday-picker :value.sync="item.weekday" small :disabled="!customizing" />
                </div>
              </div>
              <div v-if="item.repeat" class="column is-narrow">
                <div class="field">
                  <time-picker :time.sync="item.time" small :disabled="!customizing" />
                </div>
              </div>
            </div>
          </div>

          <div class="column is-narrow">
            <button class="button is-small" @click.prevent="customize">
              <octicon :icon="customizeIcon" /><span v-text="customizing ? $t('save') : $t('customize')" />
            </button>
            <button v-if="customizing" class="button is-small" @click.prevent="customizeCancel">
              <octicon :icon="sync" /><span>{{ $t('cancel') }}</span>
            </button>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <div class="field" @keydown.enter.prevent>
              <label class="label is-small">{{ $t('recipients') }}</label>
              <v-select
                v-model="item.recipients" :options="recipients" :multiple="true"
                class="is-small" :taggable="true" :disabled="!customizing"
              />
            </div>
          </div>
        </div>
      </box>
    </div>
    <widget-wall
      :layout.sync="layout" :widgets="normalizedWidgets" :customizing="customizing"
      time-lock
      @remove:widget="removeWidget" @change:widgets="changeWidgets"
    />
  </div>
</template>
<style>
.v-select .is-small,
.is-small .vs__search,
.is-small .vs__dropdown-menu,
.is-small .vs__selected {
  font-size: .75rem;
}
</style>
<script>
import { paintcan, check, sync } from 'octicons-vue'
import { WeekdayPicker, TimePicker, DayPicker } from '@cyradar/ui'
import WidgetWall from '@/components/WidgetWall'
import RangePicker from '@/components/RangePicker'
import { repeats } from '@/views/Reports'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ReportsDetails',
  components: { WidgetWall, WeekdayPicker, TimePicker, DayPicker, RangePicker, vSelect },
  data () {
    return {
      item: null,
      customizing: false,
      widgets: [],
      widgetsChanged: {},
      recipients: []
    }
  },
  computed: {
    sync () {
      return sync
    },
    customizeIcon () {
      if (this.customizing) {
        return check
      }

      return paintcan
    },
    repeats () {
      return repeats
    },
    layout () {
      if (!this.item) {
        return []
      }

      return this.item.layout
    },
    normalizedWidgets () {
      return this.widgets.map(w => {
        w.dates = []
        w.range = this.item.range

        return w
      })
    },
    widgetIDs () {
      const arr = this.layout.map(x => x.i)
      return [...new Set(arr)]
    },
    itemDates: {
      get () {
        if (!this.item.dates) {
          return []
        }

        return this.item.dates.map(date => {
          return new Date(date)
        })
      },
      set (val) {
        if (this.item.range) {
          return
        }

        const dates = val.map(x => x.toISOString())

        if (JSON.stringify(this.item.dates) === JSON.stringify(dates)) {
          return
        }

        this.item.dates = dates
      }
    }
  },
  mounted () {
    this.loadReport().then(this.loadWidgets).then(() => {
      if (!this.$route || !this.$route.params || !this.$route.params.edit) {
        return
      }

      this.customize()
    })
  },
  methods: {
    customize () {
      if (this.customizing) {
        this.customizing = false
        this.updateWidgets()
        this.updateReport()
        return
      }

      this.$_statePersist({
        item: this.item || {},
        widgets: this.widgets
      })

      this.customizing = true
    },
    customizeCancel () {
      if (!this.customizing) {
        return
      }

      const [item, widgets] = this.$_stateRestore(['item', 'widgets'])
      this.item = item
      this.widgets = widgets
      this.customizing = false
    },
    toggleItemStatus () {
      if (!this.item || !this.customizing) {
        return
      }

      this.item.enabled = !this.item.enabled
    },
    changeWidgets (change) {
      this.widgetsChanged = change
    },
    removeWidget (id) {
      if (!this.item) {
        return
      }

      if (!window.confirm(this.$t('rus'))) {
        return
      }

      this.item.layout = this.item.layout.filter(w => w.i !== id)
    },
    loadWidgets () {
      return this.$http.post('/api/v1/widgets/search', { ids: this.widgetIDs }).then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.widgets = body.data.data
      })
    },
    updateWidgets () {
      return Promise.all(
        Object.keys(this.widgetsChanged).map(id => {
          return this.$http.patch(`/api/v1/widgets/${id}`, this.widgetsChanged[id])
        })
      )
    },
    loadReport () {
      if (!this.$route || !this.$route.params || !this.$route.params.id) {
        return Promise.resolve()
      }

      return this.$http.get(`/api/v1/reports/${this.$route.params.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.item = body.data.data
      })
    },
    updateReport () {
      this.customizing = false
      const item = this.item
      if (!item) {
        return
      }

      return this.$http.patch(`/api/v1/reports/${item.id}`, item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })
      })
    }
  }
}
</script>
<style lang="scss">
.reports-details {
  padding: .75em;
}

.reports-details__bar {
  margin: 1em 0;
  padding: .75em;
  background-color: #fff;
  position: relative;
  display: block;
}
</style>
